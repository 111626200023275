import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Button, Flex, Grid, Text, useDisclosure } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { Spinner } from "@components/common/loaders";
import { SidebarContainer } from "./components/Sidebar";
import { Sections } from "./components/sections";
import { SectionSelectModal } from "@components/builder/SectionSelectModal";

import { webshopsSelector, webshopsThunks } from "@redux/webshops/webshopsSlice";
import {
  builderSelector,
  builderLoadingSelector,
  builderThunks,
  updateStorefrontSections,
  updateStorefrontHeader,
  updateStorefrontFooter,
  updateStorefrontLogin,
  updateStorefrontDeepLink,
  updatePreviewMode,
  toggleTypographyCustomizer,
} from "@redux/webshops/builder/builderSlice";
import { sectionsThunks, sectionsSelector, storefrontSectionsSelector } from "@redux/webshops/builder/sectionsSlice";
import { catalogSelector } from "@redux/webshops/catalog/catalogSlice";
import { seriesSelector } from "@redux/webshops/catalog/series/seriesSlice";
import { associationsThunks, categoriesSelector } from "@redux/webshops/associations/associationsSlice";

import { useHubspotChat } from "@src/utils/custom-hooks/useHubspotChat";

import ErrorBoundary from "@components/ErrorBoundary";
import { typographySelector } from "@redux/webshops/builder/typographySlice";
import { TypographyCustomizer } from "./components/TypographyCustomizer";

const builderAreaTemplate = `
  "builder_header builder_header builder_header"
  "builder_sections builder_preview builder_properties"
`;

export const Builder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    isOpen: isSectionSelectModalOpen,
    onClose: onCloseSectionSelectModal,
    onOpen: onOpenSectionSelectModal,
  } = useDisclosure();

  const { loadHandler, removeHandler } = useHubspotChat();

  const sectionDataRef = useRef(null);

  const { id } = useParams();

  const { catalog } = useSelector(catalogSelector);
  const series = useSelector(seriesSelector);
  const categories = useSelector(categoriesSelector);

  const fullSectionsData = useSelector(sectionsSelector);
  const { createSection } = fullSectionsData;

  const sectionsData = useSelector(storefrontSectionsSelector);
  const { canvasData = {} } = sectionsData;

  // This preview link is hardcoded for now, but it will come from the backend eventually
  const iframeRef = useRef(null);
  const [previewLink, setPreviewLink] = useState("");

  // Base webshop data, only used in the header right now
  const webshopsData = useSelector(webshopsSelector);
  const webshopsMap = webshopsData.webshopsMap;
  const [selectedWebshop, setSelectedWebshop] = useState(webshopsMap[id]);

  const { updateSelectedFont } = useSelector(typographySelector);

  const isLoading = useSelector(builderLoadingSelector);

  const builderData = useSelector(builderSelector);
  const {
    storefront = {},
    canvas = {},
    view: { isMobile, previewMode = {}, showTypographyCustomizer },
  } = builderData;

  // The current selected section
  // Needs capitalization because we use it as a component
  const [SelectedSection, setSelectedSection] = useState({
    data: {},
    ...Sections.GLOBAL,
  });

  useEffect(() => {
    // Fetch social platforms, agnostic to the webshop
    dispatch(builderThunks.getSocialPlatforms());
    const previewLink = new URL(process.env.REACT_APP_SANLO_WEBSHOPS_URL);
    previewLink.searchParams.append("webshopId", id);
    previewLink.searchParams.append("editorMode", true);
    setPreviewLink(previewLink.toString());
    // Add a listener for the iframe to send messages to the parent
    // and clean it up when the component unmounts
    const listener = (event) => {
      const { data: eventData = {} } = event;
      const { type, data } = eventData;
      if (type === "app-ready") {
        sendConfig();
      }
      if (type === "add-new-section") {
        sectionDataRef.current = data;
        onOpenSectionSelectModal();
      }
      if (type === "section-selected") {
        setSelectedSection({
          type: data.type,
          data,
          ...Sections[data.type],
        });
      }
    };

    removeHandler();
    window.addEventListener("message", listener);
    return () => {
      loadHandler();
      window.removeEventListener("message", listener);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    postToCanvas({
      type: "set-typography",
      data: storefront.data.storefront.themeV2.typography,
    });
  }, [storefront.data.storefront.themeV2.typography]);

  useEffect(() => {
    if (!selectedWebshop) {
      dispatch(webshopsThunks.getWebshops());
    }
  }, [dispatch, selectedWebshop]);

  useEffect(() => {
    if (!selectedWebshop && webshopsMap[id]) {
      // If the webshopMap updates (the webshop call succeeds) and we still
      // haven't found the selected webshop, we set it here
      setSelectedWebshop(webshopsMap[id]);
    }
    // eslint-disable-next-line
  }, [webshopsMap]);

  useEffect(() => {
    if (selectedWebshop) {
      dispatch(builderThunks.getStorefront(id));

      // Associations are needed for category
      dispatch(associationsThunks.getAssociations(id));
    }
  }, [selectedWebshop, dispatch, id]);

  useEffect(() => {
    dispatch(updateStorefrontSections(canvasData.sections));
  }, [canvasData.sections, dispatch]);

  useEffect(() => {
    dispatch(updateStorefrontHeader(canvasData.header));
  }, [canvasData.header, dispatch]);

  useEffect(() => {
    dispatch(updateStorefrontFooter(canvasData.footer));
  }, [canvasData.footer, dispatch]);

  useEffect(() => {
    dispatch(updateStorefrontLogin(canvasData.login));
  }, [canvasData.login, dispatch]);

  useEffect(() => {
    dispatch(updateStorefrontDeepLink(canvasData.deepLink));
  }, [canvasData.deepLink, dispatch]);

  useEffect(() => {
    const section = createSection.createdSection;
    if (!section || !section.id) return;
    setSelectedSection({
      type: section.type,
      data: section,
      ...Sections[section.type],
    });
  }, [createSection.createdSection]);

  useEffect(() => {
    postToCanvas({
      type: "update",
      data: canvas.data,
    });
  }, [canvas]);

  useEffect(() => {
    // Send the selected section to the canvas
    postToCanvas({
      type: "section-selected",
      data: SelectedSection.data,
    });
  }, [SelectedSection]);

  const sendConfig = (iframeWindow) => {
    postToCanvas(
      {
        type: "config",
        data: {
          // Send the canvas data to the iframe, which is the storefront data
          // and any local changes made
          storefront: canvas.data,
          editorMode: !previewMode?.active,
        },
      },
      iframeWindow,
    );
  };

  const postToCanvas = (messageData, iframeWindow) => {
    let receiver = iframeWindow || iframeRef?.current?.contentWindow;
    if (receiver) {
      receiver.postMessage(messageData, "*");
    }
  };

  const renderIframe = (preview = false) => {
    // The /storefront call is gating call for the builder in general.
    // The data is guaranteed to be here when we go to render the iframe.
    return (
      <iframe
        key="preview"
        title="Preview"
        src={previewLink}
        width={isMobile ? "430px" : "100%"}
        height="100%"
        ref={iframeRef}
        onLoad={(event) => {
          sendConfig(event.target.contentWindow);
        }}
      />
    );
  };

  const renderComponent = () => {
    if (updateSelectedFont.requestStatus.pending) {
      return <Spinner />;
    }
    return (
      <>
        {renderIframe()}
        {showTypographyCustomizer && (
          <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="rgba(0,0,0,0.5)">
            <TypographyCustomizer storefront={storefront?.data} />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Box width="100%" height="100%" data-testid="builder">
        <ErrorBoundary name="builder-container" showAltUI={isLoading} altUI={<Spinner />}>
          <ErrorBoundary
            name="builder-content"
            showAltUI={previewMode?.active}
            altUI={
              <>
                <Flex position="absolute" top="0" left="0" p="16px">
                  <Button
                    onClick={() => {
                      // when we remove the iFrame we wont need this
                      if (!state?.from.includes("/builder")) {
                        navigate(-1);
                      }
                      dispatch(updatePreviewMode({ active: false }));
                    }}
                    leftIcon={<CloseIcon w="8px" h="8px" />}
                    color="white"
                  >
                    <Text>Close</Text>
                  </Button>
                </Flex>
                {renderIframe(true)}
              </>
            }
          >
            <Grid
              height="100%"
              width="100%"
              templateColumns="260px 1fr 260px"
              templateRows="auto 1fr"
              templateAreas={builderAreaTemplate}
            >
              <SidebarContainer
                webshop={selectedWebshop}
                selectedSection={SelectedSection}
                onSectionClick={(sectionData) => {
                  dispatch(toggleTypographyCustomizer(false));
                  setSelectedSection({
                    type: sectionData.type,
                    data: sectionData,
                    ...Sections[sectionData.type],
                  });
                }}
              />
              <Flex
                gridArea="builder_preview"
                bg="#444"
                position="relative"
                height="100%"
                width="100%"
                justifyContent={"center"}
                overflow="auto"
              >
                {renderComponent()}
              </Flex>

              <Box gridArea="builder_properties" bg="dark.700" overflow="auto">
                {SelectedSection.Properties && (
                  <SelectedSection.Properties
                    key={`${SelectedSection?.data?.id}_properties`}
                    sectionId={SelectedSection?.data?.id}
                    webshop={selectedWebshop}
                    storefront={storefront?.data?.storefront}
                    categories={categories}
                    catalog={catalog}
                    series={series}
                  />
                )}
              </Box>
            </Grid>
          </ErrorBoundary>
        </ErrorBoundary>
      </Box>

      <SectionSelectModal
        isOpen={isSectionSelectModalOpen}
        onClose={onCloseSectionSelectModal}
        onSelect={(sectionType) => {
          const { type = "", seedData = {} } = sectionType;
          let order = -1;
          if (sectionDataRef.current) {
            order = sectionDataRef.current.order + 1;
          }
          dispatch(
            sectionsThunks.createSection({
              data: {
                type,
                order: order,
                ...seedData,
              },
              webshopId: id,
            }),
          );
        }}
      />
    </>
  );
};
